.wrapper {
  margin-left: 290px;
  margin-right: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  background-color: #f9fbfc;

  .content {
    width: 1140px;
    margin: 20px 0;
  }
}
