a {
  color: #222;
  text-decoration: none;
}
.wrapper {
  background-color: #fff;
  border: 1px solid #d6d4d4;
  border-radius: 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 8px;
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }

  .mainInfo {
    display: flex;

    .bgImageWrapper {
      width: 111px;
      height: 80px;
    }

    .tokenSaleData {
      margin-left: 12px;
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .type {
        margin-top: 4px;
        font-weight: normal;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 12px;
      }

      .address {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 4px;
      }

      .location {
        font-size: 14px;
        font-weight: 500;
        opacity: 0.5;
        margin-bottom: 12px;
      }

      .price {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    .approveBtn {
      background-color: #262626;
      border-radius: 6px;
      padding: 12px 48px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
      }
    }

    .moreInfo {
      color: #262626;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-decoration: underline;
      text-align: center;
      opacity: 0.5;

      &:hover {
        text-decoration: underline;
        opacity: 1;
      }
    }
  }
}
