.txSection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 24px 12px;

  .txInfo {
    display: flex;

    .percents {
      width: 48px;
      height: 48px;
      border: 2px solid #000000;
      border-radius: 6px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }

    .dateWrapper {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .date {
        font-size: 16px;
        font-weight: 500;
      }

      .time {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.5;
        margin-top: 4px;
      }
    }

    .txData {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .type {
        font-size: 12px;
        font-weight: 500;
        padding-bottom: 4px;
        opacity: 0.5;
        color: #222;
      }

      .address {
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }

  .tokensAmount {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: center;

    .amount {
      font-size: 20px;
      font-weight: 500;
    }

    .symbol {
      font-size: 12px;
      opacity: 0.5;
    }
  }
}
