.inputWrapper {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    opacity: 0.4;
    margin-bottom: 2px;
  }

  .formInput {
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #ddd;

    &.inputError {
      border: 1px solid red;
    }
  }

  .hintExample {
    font-size: 12px;
    opacity: 0.4;
    margin-top: 2px;
  }
}

.error {
  font-size: 12px;
  margin-top: 4px;
  color: #ec4949;
}
