.wrapper {
  border-radius: 6px;
  background-color: #ffffff;

  .txtLeftAlign {
    text-align: left;
  }

  .txtRightAlign {
    text-align: right;
  }

  .gridHeader {
    font-weight: 400;
    padding: 24px;
    font-size: 14px;
    opacity: 0.4;
    border-bottom: 1px solid #f0f0f0;
    text-transform: uppercase;
  }

  .gridHeaderWrapper {
    display: grid;
    grid-template-columns: 3fr 1fr repeat(3, 1fr);
  }

  .gridItemWrapper {
    cursor: pointer;
    display: grid;
    grid-template-columns: 3fr 1fr repeat(3, 1fr);
    border-bottom: 1px solid #f0f0f0;

    &:hover {
      background-color: rgba(240, 240, 240, 0.3529411765);
    }
  }

  .gridItem {
    padding: 24px;
    text-align: center;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 8px;

    .thumbWrapper {
      width: 51px;
      height: 51px;

      img {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    p {
      width: 100%;
    }
  }

  .date {
    font-size: 13px;
    font-weight: 500;
  }

  .fundProgress {
    flex-direction: column;
    text-align: right;
    justify-content: center;

    .totalSupply {
      font-size: 16px;
      font-weight: 500;
    }

    .hardcap {
      font-size: 12px;
    }
  }

  .columnWrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-align: left;

    .id {
      font-size: 11px;
      font-weight: 500;
      opacity: 0.3;
    }
    .title {
      font-size: 16px;
      font-weight: 500;
    }
    .symbol {
      font-size: 11px;
    }
  }
}
