.loadingOverlay {
  width: calc(100% - 250px);
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  opacity: 1;
  top: 0;
  left: 250px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  .loadingIcon {
    width: 100px;
    height: 100px;
  }
}
