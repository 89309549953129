.walletWrapper {
  margin-top: 20px;
  padding: 24px 12px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 6px;
  align-items: center;

  .assetName {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.7;
  }

  .assetAmount {
    font-size: 20px;
    font-weight: 500;
    text-align: right;
  }
}
