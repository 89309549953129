.wrapper {
  display: flex;
  gap: 80px;

  .commonForm {
    width: 100%;

    .mainSection {
      .formSection {
        margin-top: 18px;

        .inputWrapper {
          margin-bottom: 16px;
          display: flex;
          flex-direction: column;

          label {
            font-size: 12px;
            opacity: 0.4;
            margin-bottom: 2px;
          }

          .formInput {
            padding: 12px 16px;
            border-radius: 4px;
            font-size: 16px;
            border: 1px solid #ddd;
          }
        }

        .errorWrapper {
          background-color: rgba(239, 122, 122, 0.261);
          color: #e71212;
          padding: 16px 8px;
          border-radius: 4px;
        }
      }
    }

    .separator {
      margin: 40px 0 32px 0;
    }

    .actionBtns {
      display: flex;
      justify-content: space-between;

      .submitBtn {
        margin-top: 32px;
        width: 45%;
        color: #ffffff;
        height: 52px;
        background-color: #262626;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        letter-spacing: 0.6px;
        font-size: 16px;
        border-radius: 6px;
        cursor: pointer;
      }

      .modalBtn {
        margin-top: 32px;
        width: 45%;
        color: #ffffff;
        height: 52px;
        background-color: #e76363;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        letter-spacing: 0.6px;
        font-size: 16px;
        border-radius: 6px;
        cursor: pointer;
        border: none;
      }
    }
  }

  .additional {
    width: 340px;
    height: auto;

    .helpSection {
      padding: 20px;
      border: 1px solid #d6d4d4;
      border-radius: 6px;
      margin-bottom: 20px;

      .title {
        margin-top: 12px;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        line-height: 24px;
      }

      .desc {
        font-size: 14px;
        opacity: 0.4;
        line-height: 20px;
      }
    }
  }

  .header {
    font-size: 20px;
    font-weight: 500;
  }

  .description {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.3;
  }
}

.modalWrapper {
  .modalHeader {
    font-size: 24px;
    text-align: center;
    margin-top: 24px;
  }

  .modalDescription {
    text-align: center;
    margin-top: 8px;
    opacity: 0.5;
  }

  .modalActions {
    display: flex;
    flex-direction: column;

    .modalAccept {
      margin-top: 32px;
      color: #ffffff;
      height: 52px;
      background-color: #e76363;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.6px;
      font-size: 16px;
      border-radius: 6px;
      cursor: pointer;
      border: none;
    }

    .modalDecline {
      margin-top: 12px;
      color: #0e0e0e;
      height: 52px;
      background-color: #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.6px;
      font-size: 16px;
      border-radius: 6px;
      cursor: pointer;
      border: none;
    }
  }
}
