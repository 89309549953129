@import "reset";
@import "fonts";

body {
  font-family: "Inter";
  font-weight: normal;
  font-size: 14px;
  background-color: #f9fbfc;
  color: #0e0e0e;

  h1 {
    font-weight: bold;
  }

  h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  a {
    text-decoration: none;
  }
}
