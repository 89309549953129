.investorSection {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 16px;

  &:hover {
    background-color: #f3f3f3;
  }

  .investorInfo {
    display: flex;

    .investorName {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 4px;
        text-decoration: underline;
        color: #000000;
      }

      .email {
        font-weight: 500;
        opacity: 0.5;
      }
    }
  }

  .tokensAmount {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: center;

    .amount {
      font-size: 20px;
      font-weight: 500;
    }

    .symbol {
      opacity: 0.5;
    }
  }
}
