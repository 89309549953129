.statsCard {
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 28px; // Можно настроить отступы по вашему усмотрению
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;

    .icon {
      opacity: 0.6;
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      padding-left: 8px;
    }
  }

  .bottom {
    padding-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .statNumber {
      font-size: 28px;
      font-weight: normal;
    }

    .link {
      font-size: 15px;
      text-decoration: none;
      color: #262626;
      font-weight: 500;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}
