.inputWrapper {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  .uploadAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #7c7c7c;
    gap: 8px;

    .iconWrapper {
      height: 40px;
      opacity: 0.4;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .label {
    font-size: 12px;
    opacity: 0.4;
    margin-bottom: 2px;
  }

  .formInput {
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #ddd;
  }

  .hintExample {
    font-size: 13px;
    opacity: 0.4;
    margin-top: 4px;
  }
}

.uploadedDocuments {
  width: 100%;
  min-height: 160px;
  background-color: #f8f8f8;
  border-radius: 4px;
  color: #7c7c7c;
  // cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
  flex-direction: column;
  gap: 12px;

  .uploadedDocument {
    background-color: #ffffff;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    // gap: 40px;
    min-width: 50%;
    height: 40px;
    border-radius: 6px;
    padding: 12px;

    .docIconWrapper {
      height: 24px;

      img {
        height: 100%;
      }
    }

    .removeDoc {
      width: 32px;
      height: 32px;
      background-color: #ffffff;
      border-radius: 6px;
      display: flex;
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
}

.actionLink {
  color: #222;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 8px;
  cursor: pointer;
}

.removeLink {
  color: #b76060;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 8px;
  cursor: pointer;
}

.bottomLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
