.table {
  width: 100%;
  border-radius: 6px;
  // border: 1px solid #f0f0f0;
  background-color: #ffffff;
  border-collapse: collapse;

  thead {
    tr {
      height: 67px;
      border-bottom: 1px solid #f0f0f0;

      th {
        padding: 24px;
        font-weight: bold;
        text-align: left;
        // border-right: 1px solid #f0f0f0;
        &:last-child {
          border-right: none;
        }
      }
    }
  }

  tbody {
    tr {
      height: 88px;
      border-bottom: 1px solid #f0f0f0;
      &:hover {
        background-color: #f0f0f05a;
        cursor: pointer;
      }

      td {
        padding: 24px;
        vertical-align: middle;

        .tableLink {
          color: #0e0e0e;
          font-weight: 500;
          text-decoration: underline;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
