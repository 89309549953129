.wrapper {
  padding: 0 24px;
  height: 56px;
  background-color: #f9fbfc;
  color: #0e0e0e;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;

  .navItem {
    height: 42px;
    align-items: center;
    display: flex;
    img {
      width: 42px;
      height: 24px;
    }

    &:hover {
      background-color: #e3e4e572;
      border-radius: 8px;
      cursor: pointer;
    }

    .adminWrapper {
      align-items: center;
      display: flex;
      border-right: 1px solid #dbdee1;
      border-left: 1px solid #dbdee1;
      padding: 0 20px;
      gap: 12px;
      color: #0e0e0e;

      .adminInfoWrapper {
        .name {
          font-weight: 600;
          font-size: 14px;
          padding-bottom: 2px;
        }

        .email {
          font-weight: 500;
          opacity: 0.5;
          font-size: 12px;
        }
      }
    }
  }
}
