.wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  background-color: #fff;
  padding: 24px 12px;
  align-items: center;
  .mainInfo {
    display: flex;

    .image {
      width: 72px;
      height: 72px;
      margin-right: 8px;
    }

    .tokensaleInfo {
      .title {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        text-decoration: underline;
        margin-bottom: 8px;

        a {
          color: #000;
        }
      }

      .address {
        font-size: 12px;
        font-weight: 500;
        opacity: 0.4;
      }
    }
  }

  .tokenInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;

    span {
      font-size: 12px;
      opacity: 0.4;
    }
  }
}
