.wrapper {
  display: flex;
  gap: 80px;

  .commonForm {
    width: 100%;

    .mainSection {
      .emptyText {
        margin-top: 12px;
        opacity: 0.5;
      }

      .innerSection {
        margin-top: 20px;
        padding: 12px;
        border: 1px solid #d1d1d1;
        border-radius: 6px;

        .innerHeader {
          font-weight: 500;
          opacity: 0.4;
          font-size: 11px;
          text-transform: uppercase;
        }

        .innerDesc {
          padding-top: 4px;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;

          .imgWrapper {
            width: 380px;
          }
        }

        .progressBarData {
          text-align: right;
          margin-top: 2px;
        }

        .innerImages {
          width: 50%;
          display: flex;
          gap: 12px;
          align-items: center;

          .imgWrapper {
            width: 130px;
          }
        }

        .innerDoc {
          color: #222;
          text-decoration: underline;
          font-size: 15px;
          font-weight: 400;
        }
      }

      .investorSection {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .investorInfo {
          display: flex;

          .percents {
            width: 48px;
            height: 48px;
            border: 2px solid #000000;
            border-radius: 6px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
          }

          .dateWrapper {
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .date {
              font-size: 16px;
            }

            .time {
              font-size: 14px;
              font-weight: 600;
            }
          }

          .investorName {
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .name {
              font-size: 16px;
              font-weight: 500;
              padding-bottom: 4px;
              text-decoration: underline;
              color: #000000;
            }

            .email {
              font-weight: 500;
              opacity: 0.5;
            }
          }
        }

        .tokensAmount {
          display: flex;
          flex-direction: column;
          text-align: right;
          justify-content: center;

          .amount {
            font-size: 20px;
            font-weight: 500;
          }

          .symbol {
            opacity: 0.5;
          }
        }
      }
    }

    .separator {
      margin: 40px 0 32px 0;
    }
  }

  .additional {
    width: 340px;
    height: auto;

    .helpSection {
      padding: 20px;
      border: 1px solid #d6d4d4;
      border-radius: 6px;
      margin-bottom: 20px;

      .title {
        font-size: 12px;
        opacity: 0.3;
        font-weight: 500;
      }

      .desc {
        margin-top: 6px;
        font-size: 16px;
        font-weight: 500;

        margin-bottom: 18px;
        opacity: 0.8;

        .contractLink {
          text-decoration: underline;
          color: #222;
        }
      }
    }
  }

  .header {
    font-size: 20px;
    font-weight: 500;
  }

  .description {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.3;
  }

  .submitBtn {
    margin-top: 24px;
    width: 100%;
    color: #ffffff;
    height: 52px;
    background-color: #262626;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
  }

  .actionBtn0 {
    margin-top: 12px;
    border: 1px solid #222;
    width: 100%;
    color: #222;
    height: 52px;
    background-color: #b0afaf;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
  }

  .actionBtn1 {
    margin-top: 12px;
    border: 1px solid #222;
    width: 100%;
    color: #222;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
  }
}

.modalWrapper1 {
  width: 500px;
  /* Adjust as needed */
  max-height: 80vh;
  /* Limits modal height to 80% of the viewport */
  background: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
    .modalHeader {
        font-size: 24px;
        text-align: center;
        margin-top: 24px;
      }
    
      .inputField {
        padding: 10px;
        margin: 10px 0;
        width: 100%;
        border: 1px solid #acacac;
        border-radius: 5px;
        font-size: 14px;
      }
}

.modalContent1 {
  max-height: 60vh;
  /* Adjust height to ensure scrolling */
  overflow-y: auto;
  /* Enables vertical scrolling */
  padding-right: 10px;
  /* Prevents content from touching scrollbar */
}

/* Optional: Style scrollbar */
.modalContent1::-webkit-scrollbar {
  width: 6px;
}

.modalContent1::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.modalContent1::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.modalWrapper {
  z-index: 9999;
  .modalHeader {
    font-size: 24px;
    text-align: center;
    margin-top: 24px;
  }
  
  .inputField {
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    border: 1px solid #acacac;
    border-radius: 5px;
    font-size: 14px;
  }

  .modalDescription {
    text-align: center;
    margin-top: 8px;
    opacity: 0.5;
  }

  .modalActions {
    display: flex;
    flex-direction: column;

    .modalAccept {
      margin-top: 32px;
      color: #ffffff;
      height: 52px;
      background-color: #e76363;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.6px;
      font-size: 16px;
      border-radius: 6px;
      cursor: pointer;
      border: none;
    }

    .modalDecline {
      margin-top: 12px;
      color: #0e0e0e;
      height: 52px;
      background-color: #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.6px;
      font-size: 16px;
      border-radius: 6px;
      cursor: pointer;
      border: none;
    }
  }
}
