.wrapper {
  display: flex;

  .rightSection {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}
