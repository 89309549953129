.wrapper {
  display: flex;
  gap: 80px;

  .commonForm {
    width: 100%;

    .mainSection {
      .rentTransactions {
        border: 1px solid #d6d4d4;
        border-radius: 8px;
        margin-top: 16px;
      }
      .emptyText {
        margin-top: 12px;
        opacity: 0.5;
      }

      .innerSection {
        margin-top: 20px;
        padding: 12px;
        border: 1px solid #d1d1d1;
        border-radius: 6px;

        .innerHeader {
          font-weight: 500;
          opacity: 0.4;
          font-size: 11px;
          text-transform: uppercase;
          padding-bottom: 4px;
        }

        .innerDesc {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;

          .imgWrapper {
            width: 130px;
          }
        }

        .progressBarData {
          text-align: right;
          margin-top: 2px;
        }
      }
      .innerImages {
        width: 50%;
        display: flex;
        gap: 12px;
        align-items: center;

        .imgWrapper {
          width: 130px;
        }
      }

      .innerDoc {
        color: #222;
        text-decoration: underline;
        font-size: 15px;
        font-weight: 400;
      }
    }

    .separator {
      margin: 40px 0 32px 0;
    }
  }

  .additional {
    width: 340px;
    height: auto;

    .helpSection {
      padding: 20px;
      border: 1px solid #d6d4d4;
      border-radius: 6px;
      margin-bottom: 20px;

      .title {
        font-size: 12px;
        opacity: 0.3;
        font-weight: 500;
      }

      .desc {
        margin-top: 6px;
        font-size: 16px;
        font-weight: 500;

        margin-bottom: 18px;
        opacity: 0.8;

        .typeLabel {
          display: inline-block;
          padding: 6px 8px;
          background-color: #545454;
          color: #ffffff;
          border-radius: 4px;
        }

        .contractLink {
          text-decoration: underline;
          color: #222;
        }
      }
    }
  }

  .header {
    font-size: 20px;
    font-weight: 500;
  }

  .description {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.3;
  }

  .submitBtn {
    margin-top: 24px;
    width: 100%;
    color: #ffffff;
    height: 52px;
    background-color: #262626;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }

   .rentBtn {
    margin-top: 24px;
    width: 100%;
    color: #ffffff;
    height: 52px;
    background-color: #4e70a3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }

  .changePriceBtn {
    margin-top: 24px;
    width: 100%;
    color: #ffffff;
    height: 52px;
    background-color: #c03636;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
}

.modalWrapper {
  min-width: 520px;
  max-width: 520px;

  .modalHeader {
    font-size: 20px;
    text-align: center;
    padding-top: 16px;
    line-height: 26px;
  }

  .inputField {
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    border: 1px solid #acacac;
    border-radius: 5px;
    font-size: 14px;
  }

  .infoBanner {
    margin-top: 16px;
    background-color: #f3eeee;
    padding: 24px 16px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    border-radius: 8px;
  }

  .modalContent {
    margin: 32px 0;
  }

  .modalError {
    background-color: #fbcece;
    color: red;
    padding: 8px;
    text-align: center;
    border-radius: 6px;
    margin-bottom: 16px;
  }

  .modalActions {
    display: flex;
    flex-direction: column;

    .modalAccept {
      color: #ffffff;
      height: 52px;
      background-color: #e76363;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.6px;
      font-size: 16px;
      border-radius: 6px;
      cursor: pointer;
      border: none;
    }

    .modalDecline {
      margin-top: 12px;
      color: #0e0e0e;
      height: 52px;
      background-color: #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.6px;
      font-size: 16px;
      border-radius: 6px;
      cursor: pointer;
      border: none;
    }
  }
}
