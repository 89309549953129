.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s;
  z-index: 999;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  animation: slideIn 0.5s;
  z-index: 99999;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-200px);
  }
  to {
    transform: translateY(0);
  }
}
