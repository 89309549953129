.wrapper {
  background-color: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  height: 156px;
  flex: 0 0 calc(30.3333%);

  &:hover {
    border: 1px solid #000;
    cursor: pointer;

    .desc {
      opacity: 1;
    }
  }

  .title {
    font-size: 15px;
    font-weight: 600;
  }

  .desc {
    padding-top: 8px;
    font-size: 12px;
    line-height: 15px;
    opacity: 0.4;
  }
}
