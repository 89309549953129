.wrapper {
  margin: 140px auto;
  width: 368px;
  padding: 48px 40px 36px 40px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 8px;
  }

  .description {
    font-size: 16px;
    text-align: center;
    margin-bottom: 32px;
    opacity: 0.4;
  }

  .formWrapper {
    width: 100%;

    .inputWrapper {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;

      label {
        font-size: 12px;
        opacity: 0.4;
        margin-bottom: 2px;
      }

      .formInput {
        padding: 12px 16px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #ddd;
      }
    }

    .errorMessage {
      color: rgb(245, 69, 69);
      font-weight: 500;
    }

    .buttonForm {
      margin-top: 12px;
      width: 100%;
      height: 44px;
      background-color: rgb(81, 81, 243);
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
