.wrapper {
  width: 250px;
  height: 100vh;
  background-color: #222a32;
  color: white;
  flex-shrink: 0;
  letter-spacing: 0.3px;
  position: fixed;

  h1 {
    display: flex;
    height: 56px;
    padding: 0 24px 0 20px;
    font-size: 30px;
    align-items: center;
  }

  hr {
    border-color: #7a7f85;
    opacity: 0.4;
    padding: 4px 0;
  }

  .nav {
    padding-top: 20px;
    a {
      padding: 0 24px 0 20px;
      display: flex;
      align-items: center;
      color: white;
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      height: 44px;
      opacity: 0.5;
      margin-top: 4px;
      &.active {
        background-color: #2d353e;
        border-radius: 5px;
        opacity: 1;
        font-weight: 600;
      }
      &:hover {
        background-color: #2d353e;
        border-radius: 5px;
        opacity: 1;
      }

      &.createNewBnt {
        display: flex;
        justify-content: center;
        background-color: #2d353e;
        border-radius: 5px;
        opacity: 1;
        font-weight: 500;
        width: 218px;
        margin: 20px auto;
        font-size: 15px;
        &:hover {
          background-color: #4e545c;
        }
      }

      .iconWrapper {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }
  }
}
