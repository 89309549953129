.uploadThumbnailInput {
  display: flex;
  width: 100%;
  height: 160px;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 4px;
  cursor: pointer;
  border-top: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
  .uploadAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #7c7c7c;
    gap: 8px;

    .iconWrapper {
      height: 40px;
      opacity: 0.4;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.actionLink {
  color: #222;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 8px;
  cursor: pointer;
}

.removeLink {
  color: #b76060;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 8px;
  cursor: pointer;
}

.bottomLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imgUploaded {
  padding: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
    border: 4px solid #fff;
  }
}

.inputWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  .label {
    font-size: 12px;
    opacity: 0.4;
    margin-bottom: 2px;
  }

  .formInput {
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #ddd;
  }

  .hintExample {
    font-size: 13px;
    opacity: 0.4;
    margin-top: 4px;
  }
}

.removeBtn {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  color: #f66b6b;
  letter-spacing: 0.4px;
  text-align: center;
  margin-top: 8px;

  &:hover {
    text-decoration: underline;
  }
}
