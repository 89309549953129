.wrapper {
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 28px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  .title {
    font-size: 18px;
    font-weight: 500;
  }
  .desc {
    padding-top: 12px;
    font-size: 16px;
    line-height: 26px;
    opacity: 0.7;
  }
}
