.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: top;

  .createNewBnt {
    background-color: #2d3944;
    color: #fff;
    font-weight: 500;
    display: flex;

    align-items: center;
    height: 32px;
    padding: 8px 16px;
    border-radius: 4px;

    .iconWrapper {
      img {
        width: 75%;
      }
    }
  }
}
