.wrapper {
  display: flex;
  gap: 80px;

  .commonForm {
    width: 100%;

    .mainSection {
      .formSection {
        margin-top: 18px;
      }

      .emptyText {
        margin-top: 8px;
        opacity: 0.5;
      }
    }

    .separator {
      margin: 40px 0 32px 0;
    }
  }

  .additional {
    width: 340px;
    height: auto;

    .helpSection {
      padding: 20px;
      border: 1px solid #d6d4d4;
      border-radius: 6px;
      margin-bottom: 20px;

      .title {
        font-size: 12px;
        opacity: 0.3;
        font-weight: 500;
      }

      .desc {
        margin-top: 6px;
        font-size: 16px;
        font-weight: 500;

        margin-bottom: 18px;
        opacity: 0.8;

        .contractLink {
          text-decoration: underline;
          color: #222;
        }
      }
    }
  }

  .header {
    font-size: 20px;
    font-weight: 500;
  }

  .description {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.3;
  }

  .submitBtn {
    margin-top: 24px;
    width: 100%;
    color: #ffffff;
    height: 52px;
    background-color: #262626;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;

    a {
      color: #fff;
    }
  }

  .blockBtn {
    margin-top: 16px;
    width: 100%;
    color: #000;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    border: 2px solid black;
    text-decoration: underline;
  }
}
