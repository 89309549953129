.inputWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  max-width: 816px;

  .uploadAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #7c7c7c;
    gap: 8px;

    .iconWrapper {
      height: 40px;
      opacity: 0.4;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .actionLink {
    color: #222;
    text-decoration: underline;
    font-weight: 500;
    margin-top: 8px;
    cursor: pointer;
  }

  .label {
    font-size: 12px;
    opacity: 0.4;
    margin-bottom: 2px;
  }

  .formInput {
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #ddd;
  }

  .hintExample {
    font-size: 13px;
    opacity: 0.4;
    margin-top: 4px;
  }
}

.uploadedImages {
  width: 100%;
  min-height: 160px;
  background-color: #f8f8f8;
  border-radius: 4px;
  color: #7c7c7c;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-top: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;

  .uploadedImgsWrapper {
    width: 100%;
    padding: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;

    .uploadedImage {
      height: 100%;
      position: relative;
      img {
        width: 100%;
        height: 134px;
        object-fit: contain;
        border-radius: 6px;
        border: 4px solid #fff;
      }

      .removeActionBtn {
        position: absolute;
        top: -4px;
        right: -4px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #222;
        border: 2px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          border-radius: none;
          border: none;
          width: 50%;
          height: 50%;
        }
      }
    }
  }
}

.actionLink {
  color: #222;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 8px;
  cursor: pointer;
}

.removeLink {
  color: #b76060;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 8px;
  cursor: pointer;
}

.bottomLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
